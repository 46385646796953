<template>
  <contentSeparator />
</template>
<script setup lang="ts">
const contentSeparator = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('@/components/block/seperator/vdv/contentSeparator.vue');
  } else {
    return import('@/components/block/seperator/minilu/contentSeparator.vue');
  }
});
</script>
